
  import { Component, Vue, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters } from 'vuex'
  import { Cursor } from '@/entities/public/Resource/interfaces'
  import { ResourceData } from '@/store/resources/datatables/state'
  import { Resource } from '@/entities/public'
  import { View } from '@/entities/public/Resource/metadata'
  import { capitalize, genericViews } from '@/utils/general'

@Component({
  components: {},
  methods: {
    ...mapActions('resources', ['setCursorSize', 'setCursorPage']),
  },
  computed: {
    ...mapGetters('resources/datatables', ['data']),
    ...mapGetters('resources', ['cursor', 'active']),
    ...mapGetters('app', ['isMobile']),
  },
})
  export default class Footer extends Vue {
  itemsPerPage: Array<number> = [5, 10, 20, 30, 40, 50]
  isVersionManager: boolean = false

  cursor!: Cursor
  active!: Resource
  data!: ResourceData
  isMobile!: boolean

  setCursorSize!: (size: number) => void
  setCursorPage!: (page: number) => void

  mounted () {
    const { $route: { query: { page, size } } } = this
    if (page && size) {
      this.setCursorPage(Number(page))
      this.setCursorSize(Number(size))
    }
  }

  get metadata (): View {
    const { active: { metadata } } = this

    return metadata as View
  }

  get isSpawnable (): boolean {
    const { metadata } = this

    return Boolean(metadata?.forms?.create)
  }

  get model (): string {
    const { metadata: { query: { model } } } = this

    return model
  }

  async createForm () {
    const { model, active, metadata: { forms: { create: form } } } = this

    active.refresh()
    await this.$router.push({
      name: genericViews[capitalize(form)],
      params: { model },
      query: { form, returnTo: active.slug },
    }).catch(() => {
    })
  }

  @Watch('cursor', { immediate: true, deep: true })
  onCursorChange (val, prev) {
    const query = this.$route.query
    const cursorExist = val?.page && val?.size
    const queryExist = query?.page && query?.size
    const isDifferentPage = Number(query?.page) !== val?.page
    const isDifferentSize = Number(query?.size) !== val?.size
    if (isDifferentSize) {
      this.cursor.page = 1
    }

    if ((cursorExist && !queryExist) || ((isDifferentPage || isDifferentSize) && prev)) {
      const query = Object.assign({}, this.$route.query, { page: isDifferentSize ? 1 : val.page, size: val.size })
      this.$router.replace({ query }).catch(() => {
      })
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  onRouteChange (val) {
    this.isVersionManager = !!val.path.includes('version_manager')
  }
  }
